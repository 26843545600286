import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 5×2\\@80% 1RM`}</p>
    <p>{`Band Face Pulls 5×10`}</p>
    <p>{`then,`}</p>
    <p>{`Four 2:00 rounds of:`}</p>
    <p>{`20/15 Calorie Row`}</p>
    <p>{`Max S2OH (135/95)(RX+ 155/105)`}</p>
    <p>{`Rest 1:00 between rounds`}</p>
    <p>{`Score = Total S2OH reps`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      